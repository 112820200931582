<template>
    <div>
		<div class="contentWrapper">
			<div style="width: 200px;">
				<v-select
					v-model="worker.status"
					:items="dropdownStatus"
					item-text="name"
					item-value="value"
					label="Status"
					:readonly="disableEdit"
				></v-select>
			</div>

			<v-text-field
				v-model="worker.personalData.lastName"
				:error="errors.lastName.isError"
				:error-messages="errors.lastName.errorMessage"
				:rules="validationRules.lastNameRules"
				:readonly="disableEdit"
				required
				label="Lastname"
				@input="() => { errors.lastName.valueEntered = true; setFieldError('lastName', false, '');}"
			></v-text-field>

			<v-text-field
				v-model="worker.personalData.firstName"
				:error="errors.firstName.isError"
				:error-messages="errors.firstName.errorMessage"
				:rules="validationRules.firstNameRules"
				:readonly="disableEdit"
				required
				label="Firstname"
				@input="() => { errors.firstName.valueEntered = true; setFieldError('firstName', false, '');}"
			></v-text-field>


			<v-row class="px-3">
				<v-dialog
					v-model="birthDateOpened"
					width="290px"
					:disabled="disableEdit"
					persistent
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							v-model="worker.personalData.birthDate"
							prepend-icon="mdi-calendar"
							readonly
							label="Birth date"
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</template>

					<v-card>
						<v-date-picker
							:min="minBirthDate"
							:max="maxBirthDate"
							v-model="worker.personalData.newBirthDate"									
						></v-date-picker>
							<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								text
								@click="() => {birthDateOpened = false; worker.personalData.newBirthDate=worker.personalData.birthDate;}"
							>
								Cancel
							</v-btn>
							<v-btn
								color="primary"
								text
								@click="() => { (worker.personalData.newBirthDate != '') ? saveBirthDate() : null;}"
							>
								Save
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>	

				<v-text-field
					type="tel"
					v-model="worker.personalData.personalId"
					label="External ID"
					counter
					@keypress="isDigit($event)"
					maxlength="50"
					class="ml-10"
				></v-text-field>
			</v-row>

			<v-text-field
				v-model="worker.personalData.email"
				label="Email"
				:error="errors.email.isError"
				:error-messages="errors.email.errorMessage"
				:rules="validationRules.emailRules"
				:readonly="disableEdit"
				@input="() => { errors.email.valueEntered = true; setFieldError('email', false, ''); }"
			></v-text-field>	

			<v-text-field
				v-model="worker.personalData.phoneNumber"
				label="Phone number"
				:error="errors.phoneNumber.isError"
				:error-messages="errors.phoneNumber.errorMessage"
				:rules="validationRules.phoneNumberRules"
				:readonly="disableEdit"
				@input="() => { errors.phoneNumber.valueEntered = true; setFieldError('phoneNumber', false, ''); }"
			></v-text-field>	

			<v-textarea
				v-model="worker.personalData.comment"
				solo
				label="Comment..."
				outlined
				:readonly="disableEdit"
				rows="3"
				maxlength="500"
				counter
				class="my-3"
			></v-textarea>

			<v-row 
				v-if="!disableEdit"
				class="px-7 pb-7" 
				style="display:flex; justify-content: flex-end;"
			>
				<v-btn
                    color="grey"
                    class="mr-3 mt-0"
                    text
					@click="changesProvided? $emit('cancel-clicked'): $router.push('/workers')"
                >CANCEL</v-btn>
				<v-btn
					color="primary"
					class="px-15"
					:disabled="saveDisabled"
					@click="saveChanges()"
				>SAVE</v-btn>
			</v-row>
		</div>

		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
					{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
					{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
// Takes too long! Moved to beforeCreate
//import PhoneNumber from 'awesome-phonenumber';
//const PhoneNumber = require('awesome-phonenumber');
var PhoneNumber = null;


export default {
	async beforeCreate() {
		PhoneNumber = await import('awesome-phonenumber');
	},

	name: 'WorkerPersonalData',

	components: {
	},

	data: function(){
		return {
			worker: {
				personalData: {
					firstName: null,
					lastName: null,
					email: null,
					phoneNumber: null,
					birthDate: null,
					comment: null,
					personalId: null,
				},
				status: 0,
			},
			originalWorker: {
				personalData: {
					firstName: null,
					lastName: null,
					email: null,
					phoneNumber: null,
					birthDate: null,
					comment: null,
					personalId: null,
				},
				status: 0,
			},
			mutableWorkerId: this.workerId,
			mutableWorkerFullName: this.workerFullName,
			dropdownStatus: [
				{
					name: "Active",
					value: 0
				},
				{
					name: "Inactive",
					value: 1
				},
			],
			errors: {
				lastName: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				firstName: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				email: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				phoneNumber: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
				birthDate: {
					isError: false,
					errorMessage: "",
					valueEntered: false,
				},
			},
			validators: {
				lastName: this.validateLastName,
				firstName: this.validateFirstName,
				email: this.validateEmail,
				phoneNumber: this.validatePhoneNumber,
				birthDate: this.validateBirthDate,
			},
			validationRules: {
				lastNameRules: [ v => this.validateLastName(v) || this.errors.lastName.errorMessage ],
				firstNameRules: [ v => this.validateFirstName(v) || this.errors.firstName.errorMessage ],
				emailRules: [ v => this.validateEmail(v) || this.errors.email.errorMessage ],
				phoneNumberRules: [ v => this.validatePhoneNumber(v) || this.errors.phoneNumber.errorMessage ],
				birthDateRules: [ v => this.validateBirthDate(v) || this.errors.birthDate.errorMessage ],
			},
			birthDateOpened: false,
			minBirthDate: null,
			maxBirthDate: null,
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			},
			permissions: [],
			disableEdit: false,
		}
	},
    props: {
		workerId: Number,
		workerFullName: String,
    },
	computed: {
		saveDisabled(){
			for(let item in this.errors){
				if(this.errors[item].isError){
					return true
				}
			}
			if(!this.changesProvided){
				return true
			}
			return false
		},
		changesProvided(){
			for(let key in this.worker.personalData){
				if(this.worker.personalData[key]!=this.originalWorker.personalData[key]){
					return true
				}
			}
			if(this.worker.status!=this.originalWorker.status){
				return true
			}
			return false
		}
	},
	beforeMount(){
		if(localStorage.getItem('permissions') !== null){
			this.permissions = JSON.parse(localStorage.permissions);
		}
	},

    mounted() {
		this.$emit("set-state", "default");
		if (!this.permissions.includes("workers_view")) {
            this.$emit('set-state', 'ERROR', this.lviews.youDoNotHavePriviledgesToThisView);
            return
		}
		if (!this.permissions.includes("workers_change")) {
            this.disableEdit=true
		}
		if(this.mutableWorkerFullName && this.mutableWorkerId){
			localStorage.cardsWorkerFullName=JSON.stringify(this.mutableWorkerFullName)
			localStorage.cardsWorkerId=JSON.stringify(this.mutableWorkerId)
		}
		else{
			this.mutableWorkerFullName = JSON.parse(localStorage.cardsWorkerFullName)
			this.mutableWorkerId = JSON.parse(localStorage.cardsWorkerId)
		}
		this.minBirthDate = "1920-01-01";
		this.maxBirthDate =  new Date().toJSON().slice(0, 10).replace("/-/g", "/");
		this.fetchData()
	},
    methods: {
		fetchData(){
			this.$emit("set-state", "LOADING_OVERLAYER");
			axios({
				method:'get',
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+this.mutableWorkerId,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
			}).then((response) => {
				this.adaptApiResponseWorker(response.data)
				this.$emit("set-state", "default");
			})
			.catch((error) => {
				this.$emit("set-state", "default");
				if (typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			})
		},
		adaptApiResponseWorker(data){
			this.worker = {
				personalData: {
					firstName: data.personal_data.first_name,
					lastName: data.personal_data.last_name,
					email: data.personal_data.email,
					phoneNumber: data.personal_data.phone_number,
					birthDate: data.personal_data.birth_date,
					comment: data.personal_data.comment,
					personalId: data.personal_data.unique_personal_id,
				},
				status: data.status,
			}
			this.originalWorker={
				personalData: {
					firstName: data.personal_data.first_name,
					lastName: data.personal_data.last_name,
					email: data.personal_data.email,
					phoneNumber: data.personal_data.phone_number,
					birthDate: data.personal_data.birth_date,
					comment: data.personal_data.comment,
					personalId: data.personal_data.unique_personal_id,
				},
				status: data.status,
			}
		},
		saveChanges(){
			this.$emit("set-state", "LOADING_OVERLAYER", "Save changes...");
			axios({
					method:'patch',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/workers/"+this.mutableWorkerId,
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					},
					data: {
						personal_data: {
							first_name: this.worker.personalData.firstName,
							last_name: this.worker.personalData.lastName,
							email: this.worker.personalData.email,
							phone_number: this.worker.personalData.phoneNumber,
							birth_date: this.worker.personalData.birthDate,
							comment: this.worker.personalData.comment,
							unique_personal_id: this.worker.personalData.personalId,
						},
						status: this.worker.status,
					}
				}).then(() => {
					this.$emit("set-state", "DEFAULT");
					this.fetchData()
					this.$emit('action-result', 'success', 'Saved changes');
					this.$emit('set-new-title', this.worker.personalData.firstName+" "+this.worker.personalData.lastName);
				})
				.catch((error) => {
					this.$emit("set-state", "DEFAULT");
					if (typeof(error.response) === 'undefined'){
						this.$emit('action-result', 'error', 'Application failed to connect to the server. Please check your internet connection.');
						return;
					}

					switch(error.response.status){
						case 401:
							// Redirect to login page
							this.$router.push("/");
							return;
						case 403:
							// Redirect to login page
							this.$emit('action-result', 'error', 'You do not have priviledges to this action');
							return;
						case 429:
							this.$emit('action-result', 'error', 'Server received too many reuqests from your browser. Please wait a minute before trying again.');
							return;
						case 400:
							if(error.response.data.personal_data && error.response.data.personal_data.unique_personal_id &&  error.response.data.personal_data.unique_personal_id[0].code=="unique"){
								this.$emit('action-result', 'error', 'Personal data with this external ID already exists');
								return;
							}
							this.$emit('action-result', 'error', 'Server rejected your request. Please make sure your application is up to date (reload the website)');
							return;
						case 500:
							this.$emit('action-result', 'error', 'An internal server error has occured. Please report this message to the administrator.');
							return;
						default:
							this.$emit('action-result', 'error', "An unknown error has occured. That's all we know");
							return;
					}
				})
		},
		validateLastName: function(lastNameValue) {
			if (this.errors.lastName.valueEntered) {
				if (lastNameValue == "" || lastNameValue == null || lastNameValue == undefined) {
					this.setFieldError("lastName", true, "Last name required");
					return false;
				}

				this.setFieldError("lastName", false, "");
				return true;
			}
			return true;
		},
		validateFirstName: function(firstNameValue) {
			if (this.errors.firstName.valueEntered) {
				if (firstNameValue == "" || firstNameValue == null || firstNameValue == undefined) {
					this.setFieldError("firstName", true, "First name required");
					return false;
				}				
				
				this.setFieldError("firstName", false, "");
				return true;
			}
			return true;
		},
		validateEmail: function(emailValue) {
			if (this.errors.email.valueEntered) {
				if (emailValue == "" || emailValue == null || emailValue == undefined) {
					this.setFieldError("email", false, "");
					return true
				}

				let result = /.+@.+\..+/.test(emailValue);
				if (!result) {
					this.setFieldError("email", true, "Invalid email structure");
					return false;
				}
				this.setFieldError("email", false, "");
				return true;
			}
			return true
		},
		validatePhoneNumber: function(phoneNumberValue) {
			if (this.errors.phoneNumber.valueEntered) {
				if (phoneNumberValue != null && phoneNumberValue != "") {
					let validators = [
						new PhoneNumber(phoneNumberValue, "PL"),
						new PhoneNumber(phoneNumberValue, "GB"),
						new PhoneNumber(phoneNumberValue, "DE"),
						new PhoneNumber(phoneNumberValue, "UA"),
						new PhoneNumber(phoneNumberValue, "US")
					];

					let anyValidatorValid = false, value;
					validators.forEach(validator => {
						value = validator.isValid();
						if (value) {
							anyValidatorValid = true;
						}
					});

					if (!anyValidatorValid) {
						this.setFieldError("phoneNumber", true, "Invalid phone number structure");
						return false;
					}

					if (!phoneNumberValue.toString().startsWith('+')) {
						this.setFieldError("phoneNumber", true, "No area code entered");
						return false;
					}

					this.setFieldError("phoneNumber", false, "");
					return true;
				}
				this.setFieldError("phoneNumber", false, "");
				return true;
			}
			return true;
		},
		isDigit(evt) {
			evt = (evt) ? evt : window.event;
			var charCode = (evt.which) ? evt.which : evt.keyCode;

			if (charCode >= 48 && charCode <= 57){
				return true;
			}
			evt.preventDefault();
		},
		setFieldError: function(field, value, message) {
			this.errors[field].isError = value;
			this.errors[field].errorMessage = message;
		},
		saveBirthDate(){
			this.worker.personalData.birthDate=this.worker.personalData.newBirthDate
			this.birthDateOpened=false
		},
    },
	watch:{
		changesProvided: function(){
			if(this.changesProvided){
				this.$emit('disable-go-back', true)
			}
			else{
				this.$emit('disable-go-back', false)
			}
		}
	}
	};
</script>

<style scoped>

.contentWrapper{
    padding: 20px;
    width: 600px;
	overflow:visible;
}

</style>
